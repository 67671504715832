// Generated by Framer (575ceb6)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/0jtlswBvrookcx7EpjBD/Video.js";
const VideoFonts = getFonts(Video);

const cycleOrder = ["U77aN7NQg"];

const variantClassNames = {U77aN7NQg: "framer-v-u97kdw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "U77aN7NQg", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "U77aN7NQg", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-k7e4i", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-u97kdw", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"U77aN7NQg"} ref={ref} style={{borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} transition={transition}><motion.div className={"framer-1ocqyh1-container"} layoutDependency={layoutDependency} layoutId={"q6IDwyPyZ-container"} transition={transition}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={12} bottomLeftRadius={12} bottomRightRadius={12} canvasPlay={false} controls={false} height={"100%"} id={"q6IDwyPyZ"} isMixedBorderRadius={false} layoutId={"q6IDwyPyZ"} loop muted objectFit={"fill"} playing={false} posterEnabled={false} srcFile={new URL("assets/6nwtw2rhDthzhEW899gWRFFUkdE.mp4", import.meta.url).href} srcType={"Upload"} srcUrl={"https://assets.mixkit.co/videos/preview/mixkit-ice-cream-glass-of-red-soda-5094-small.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={12} topRightRadius={12} volume={25} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-k7e4i [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-k7e4i .framer-ai2xau { display: block; }", ".framer-k7e4i .framer-u97kdw { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 415px; justify-content: center; padding: 0px 0px 0px 0px; position: relative; width: 624px; }", ".framer-k7e4i .framer-1ocqyh1-container { flex: 1 0 0px; height: 1px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-k7e4i .framer-u97kdw { gap: 0px; } .framer-k7e4i .framer-u97kdw > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-k7e4i .framer-u97kdw > :first-child { margin-top: 0px; } .framer-k7e4i .framer-u97kdw > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 415
 * @framerIntrinsicWidth 624
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerfD94sc9ta: React.ComponentType<Props> = withCSS(Component, css, "framer-k7e4i") as typeof Component;
export default FramerfD94sc9ta;

FramerfD94sc9ta.displayName = "paused - tablet";

FramerfD94sc9ta.defaultProps = {height: 415, width: 624};

addFonts(FramerfD94sc9ta, [...VideoFonts])